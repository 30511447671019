// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-tsx": () => import("./../../../src/pages/live.tsx" /* webpackChunkName: "component---src-pages-live-tsx" */),
  "component---src-pages-livetest-tsx": () => import("./../../../src/pages/livetest.tsx" /* webpackChunkName: "component---src-pages-livetest-tsx" */),
  "component---src-pages-practice-tsx": () => import("./../../../src/pages/practice.tsx" /* webpackChunkName: "component---src-pages-practice-tsx" */),
  "component---src-pages-program-tsx": () => import("./../../../src/pages/program.tsx" /* webpackChunkName: "component---src-pages-program-tsx" */)
}

